import React, { useState } from 'react'
import { useTransition, animated } from 'react-spring'
import OrganizationAvatar from '../components/OrganizationAvatar'
import withViewer from '../graphql/withViewer'
import withSwitchOrganization from '../graphql/withSwitchOrganization'
import { ChevronDown, Settings } from 'react-feather'
import { message, Button, Skeleton, notification } from 'antd'
import LoadingIndicator from './LoadingIndicator'
import Input from './Input'
import withOrganizationSearch from '../graphql/withOrganizationSearch'
import useDebounce from '../hooks/useDebounce'
import Link from 'next/link'


const switchOrganization = async (props, organization) => {
  const msg = message.loading(`Switching accounts...`);
  try {
    props.setIsVisible(false);
    const result = await props.switchCurrentOrganization({ variables: { organizationId: organization.id } });
    await props.viewerRefetch();
    notification['info']({
      message: 'Account Changed',
      description: <span>Account changed to <b>{organization.name}</b></span>,
      placement: 'bottomRight'
    });
    
  } catch (e) {
    console.log(e);
    msg.then(() => message.error((<span>Error switching to <b>{organization.name}</b></span>), 1.0));
  }
}

const Menu = props => {
  const { viewerData, viewerLoading, organizationSearchData = {}, organizationSearchLoading, organizationSearchError } = props;
  const { searchOrganizations = {} } = organizationSearchData;
  let organizations = [];
  const currentOrg = (viewerData && viewerData.currentOrganization) || {};
  const orgUrl = (Object.entries(currentOrg).length !== 0) ? `/admin/orgs/view?id=${currentOrg.id}` : null;
  let orgs = [];
  if (!organizationSearchLoading && organizationSearchData) {
    const {items:organizationItems = []} = searchOrganizations || {};
    orgs = organizationItems.filter(org => org.id !== currentOrg.id);
    organizations = orgs.map(item => {
      return (
        <div key={item.id} onClick={evt => switchOrganization(props, item)} className={'container'}>
          <OrganizationAvatar title={item.name} photo={item.photo} />
          <div className="org-name">{item.name}</div>
          <style jsx>{`
            .container {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0.5em;
              cursor: pointer;
              z-index: 9999;
            }
            .container:hover {
              background-color: #e6f7ff;
              color: blue;
            }
            .org-name{
              font-weight: bolder;
              margin: 0 1.0em;
              font-size: 0.85em;
            }
            
          `}</style>
        </div>
      )
    })
  }

  return (
    <div className="container">
      <OrganizationHeader {...props} active={true} isLoading={viewerLoading} />
      <div className="content">
        {props.canViewPolicy('ORGANIZATION') && orgUrl && <div className="settings"><Link prefetch href="/organization"><Button onClick={evt => props.setIsVisible(false)} style={{ display: 'flex', margin: '1.0em 0em', flex: 1, justifyContent: 'center' }}><Settings size="1.5em" style={{ marginRight: '1.0em' }} /><div>Organization Settings</div></Button></Link></div>}
        <div className="search-container search-spacer">
          <Input autoFocus onChange={evt => props.setSearchQuery(evt.target.value)} containerStyle={{ width: '100%' }} type="text" placeholder="Search organizations..." />
          <div style={{ flex: 1 }} />
        </div>
        {organizations}
        {orgs.length == 0 && !props.organizationSearchLoading && <div className="no-results"><p>No search results</p></div>}
        {props.organizationSearchLoading && <div className="loading"><LoadingIndicator /></div>}
      </div>
      <style jsx>{`
      .container {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 2;
        top: 0;
        font-size: 1.0em;
        box-shadow: 0px 3px 5px rgba(0,0,0,0.25);
        margin: 0;
        padding: 0;
        background-color: white;
      }

      .search-spacer{
        margin-bottom: 1.0em;
      }

      .content{
        padding: 0.5em;
      }

      .no-results{
        display: flex;
        flex: 1;
        justify-content: center;
      }

      .loading{
        margin: 1.0em 0;
        display: flex;
        flex: 1;
      }

      .divider-title{
        text-overflow: ellipsis;
        max-width: 150px;
        overflow: hidden;
        margin: 0px;
      }

      .settings{
        display: flex;
        align-items: center;
        flex: 1;
      }

      .settings-org-name{
        font-weight: bolder;
        font-size: 1.0em;
        margin-left: 10px;
      }

      @keyframes fade-in {
        from { opacity: 0; }
        to   { opacity: 1; }
      }    
    `}</style>
    </div>
  )
}


const MenuWithOrganizations = withOrganizationSearch(Menu);


const OrganizationHeader = props => {
  const { viewerLoading, viewerError, viewerData, isLoading, onClick, active } = props;
  const organizationName = viewerData && viewerData.currentOrganization && viewerData.currentOrganization.name || 'No Organization';
  const organizationPhoto = viewerData && viewerData.currentOrganization && viewerData.currentOrganization.photo;
  const transitions = useTransition(!isLoading, null, {
    from: { opacity: 1 },
    enter: { opacity: 1, },
    leave: { opacity: 0, position: 'absolute' },
  });

  return transitions.map(({ item, key, props }) =>
    <animated.div data-cy="organizationSelector" key={key} style={props}>
      <div onClick={onClick} className={`container ${active ? 'active' : ''}`}>
        <OrganizationAvatar isLoading={isLoading} isRect={true} title={organizationName} photo={organizationPhoto} />
        {!isLoading && <div className="name">{organizationName}</div>}
        {isLoading && <div style={{marginLeft: '1.0em'}}><Skeleton title={{width: '150px'}} paragraph={false} loading={isLoading} /></div>}
        <div style={{ flex: 1 }} />
        {!isLoading && <div className="chevron">
          <ChevronDown color="white" />
        </div>}
        <style jsx>{`
        .container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          user-select: none;
          padding: 0 1.0em;
          // max-width: 250px;
          min-width: 300px;
          height: 60px;
        }
        .container:hover {
          background-color: #1D4F88;
        }
        .active{
          background-color: #1D4F88;
        }
        .chevron{
          min-width: 24px;
        }
        .name {
          font-size: 1.0em;
          color: white;
          margin: 0px 1.0em;
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 100;
        }

        @media (max-width: 800px) { 
            .name{
              display: none;
            }
            .container {
              width: auto;
              min-width: auto;
            }
            .chevron{
              display: none;
            }
        }
      `}</style>
      </div>
    </animated.div>
  )

}


const onVisibleChange = (evt, props, setIsVisible, isVisible, setSearchQuery) => {
  evt.stopPropagation();
  evt.preventDefault();
  setIsVisible(!isVisible);
  setSearchQuery('');
}

const Component = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 500);

  const { viewerLoading } = props;

  return (
    <div className="container">
      <OrganizationHeader onClick={evt => onVisibleChange(evt, props, setIsVisible, isVisible, setSearchQuery)} {...props} isLoading={viewerLoading} />
      {isVisible && <div className="hidden-modal" onClick={evt => setIsVisible(false)} />}
      {isVisible && <MenuWithOrganizations {...props} searchOrganizationActiveFilter={true} setSearchQuery={setSearchQuery} searchOrganizationQuery={debouncedQuery} isVisible={isVisible} setIsVisible={setIsVisible} />}
      <style jsx>{`
      .container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.0em;
        height: 60px;
        margin: 0;
        padding: 0;
      }
      .hidden-modal{
        position: fixed;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        z-index: 1;
      }
    `}</style>
    </div>
  )
}
export default withViewer(withSwitchOrganization(Component));