export const IDLE_SESSION_TIMEOUT_IN_MILLISECONDS = 1000 * 60 * 15;

export const ROLE_RESOURCE_TEXT = {
  USER: {
    title: 'Users',
    subtitle: "Manage other users within the same organization.",
  },
  USER_GROUP: {
    title: 'User Groups',
    subtitle: "Manage other user groups within the same organization.",
  },
  USER_TASK: {
    title: 'User Tasks',
    subtitle: "Access to User Task module.",
  },
  ROLES: {
    title: 'Roles',
    subtitle: "Manage roles within the organization.",
  },
  ORGANIZATION: {
    title: 'Organization',
    subtitle: "Manage organization profile details.",
  },
  ORG_INTERVENTIONS: {
    title: 'Interventions',
    subtitle: "Manage organization's interventions.",
    parent: 'ORGANIZATION',
  },
  ORG_AUTHS: {
    title: 'Authorizations',
    subtitle: "Manage intervention's authorizations.",
    parent: 'ORGANIZATION',
  },
  ORG_TASKS: {
    title: 'Tasks',
    subtitle: "Manage intervention's tasks.",
    parent: 'ORGANIZATION',
  },
  PATIENT: {
    title: 'Patient',
    subtitle: "Manage patient's profile details.",
  },
  PATIENT_ORDER_PERSONAL_CARE: {
    title: 'Order Personal Care',
    subtitle: "Order personal care for a patient.",
    parent: 'PATIENT',
  },
  PATIENT_INTERVENTIONS: {
    title: 'Interventions',
    subtitle: "Manage patient's intervention.",
    parent: 'PATIENT',
  },
  PATIENT_AUTHS: {
    title: 'Authorizations',
    subtitle: "Manage patient's authorization(s).",
    parent: 'PATIENT',
  },
  PATIENT_TASKS: {
    title: 'Tasks',
    subtitle: "Manage patient's task(s).",
    parent: 'PATIENT',
  },
  REPORTS: {
    title: 'Reports',
    subtitle: "Access to Reports module.",
  }
};

export const POLICY_DEFAULTS = () => [
  {
    resource: 'USER',
    action: 'DISABLED',
    effect: 'ALLOW'
  },
  {
    resource: 'USER_GROUP',
    action: 'DISABLED',
    effect: 'ALLOW'
  },
  {
    resource: 'USER_TASK',
    action: 'DISABLED',
    effect: 'ALLOW'
  },
  {
    resource: 'ROLES',
    action: 'DISABLED',
    effect: 'ALLOW'
  },
  {
    resource: 'ORGANIZATION',
    action: 'DISABLED',
    effect: 'ALLOW'
  },
  {
    resource: 'ORG_INTERVENTIONS',
    action: 'DISABLED',
    effect: 'ALLOW'
  },
  {
    resource: 'ORG_AUTHS',
    action: 'DISABLED',
    effect: 'ALLOW'
  },
  {
    resource: 'ORG_TASKS',
    action: 'DISABLED',
    effect: 'ALLOW'
  },
  {
    resource: 'PATIENT',
    action: 'DISABLED',
    effect: 'ALLOW'
  },
  {
    resource: 'PATIENT_ORDER_PERSONAL_CARE',
    action: 'DISABLED',
    effect: 'ALLOW'
  },
  {
    resource: 'PATIENT_INTERVENTIONS',
    action: 'DISABLED',
    effect: 'ALLOW'
  },
  {
    resource: 'PATIENT_AUTHS',
    action: 'DISABLED',
    effect: 'ALLOW'
  },
  {
    resource: 'PATIENT_TASKS',
    action: 'DISABLED',
    effect: 'ALLOW'
  },
  {
    resource: 'REPORTS',
    action: 'DISABLED',
    effect: 'ALLOW'
  }
];

export const STATE_HASH =  {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AS': 'American Samoa',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District Of Columbia',
  'FM': 'Federated States Of Micronesia',
  'FL': 'Florida',
  'GA': 'Georgia',
  'GU': 'Guam',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MH': 'Marshall Islands',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'MP': 'Northern Mariana Islands',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PW': 'Palau',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VI': 'Virgin Islands',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming'
};

export const ORG_TYPES = {
  na: "N/A",
  prov: "Healthcare Provider",
  dept: "Hospital Department",
  team: "Organizational Team",
  govt: "Government",
  ins: "Insurance Company",
  edu: "Educational Institution",
  relg: "Religious Institution",
  crs: "Clinical Research Sponsor",
  cg: "Community Group",
  bus: "Non-Healthcare Business or Corporation",
  other: "Other",
};

export const AUTHORIZED_UNITS_OF_TIME_MAP = [
  { value: 'PERIOD_PER_DAY', display: 'Day' },
  { value: 'PERIOD_PER_WEEK', display: 'Week' },
  { value: 'PERIOD_PER_MONTH', display: 'Month' },
  { value: 'PERIOD_PER_2_WEEKS', display: '2 Weeks' },
  { value: 'PERIOD_PER_YEAR', display: 'Year' },
  { value: 'PERIOD_PER_AUTHORIZATION', display: 'Authorization' },
  { value: 'PERIOD_PER_QUARTER', display: 'Quarter' },
]

export const AUTHORIZATION_UNIT_TYPES = [
  { value: 'BASED_ON_HOURS', display: 'Hours' },
  { value: 'BASED_ON_VISITS', display: 'Visits' },
]

export const AUTHORIZATION_UNIT_TYPES_MAP = {
  'BASED_ON_HOURS': 'Hours',
  'BASED_ON_VISITS': 'Visits',
}

export const AUTHORIZATION_UNIT_TYPES_SINGULAR = [
  { value: 'BASED_ON_HOURS', display: 'Hour' },
  { value: 'BASED_ON_VISITS', display: 'Visit' },
]

export const AUTHORIZATION_UNIT_TYPES_SINGULAR_MAP = {
  'BASED_ON_HOURS': 'Hour',
  'BASED_ON_VISITS': 'Visit',
}

export const UNITS_OF_TIME = [
  { value: 'SECOND', display: 'Second' },
  { value: 'MINUTE', display: 'Minute' },
  { value: 'HOUR', display: 'Hour' },
  // { value: 'PERIOD_PER_DAY', display: 'Day' },
  // { value: 'PERIOD_PER_WEEK', display: 'Week' },
  // { value: 'PERIOD_PER_MONTH', display: 'Month' },
  // { value: 'PERIOD_PER_YEAR', display: 'Year' },
]

export const UNITS_OF_TIME_MAP = {
  'SECOND': 'Second',
  'MINUTE': 'Minute',
  'HOUR': 'Hour',
  'DAY': 'Day',
  'WEEK': 'Week',
  'MONTH': 'Month',
  'YEAR': 'Year',
}

export const SHIFT_HOURS = [
  { optionVal: '7to12', display: '7am to 12pm', dbVal: { low: 7, high: 12 } },
  { optionVal: '12to5', display: 'Noon to 5pm', dbVal: { low: 12, high: 17 } },
  { optionVal: '5to11', display: '5pm to 11pm', dbVal: { low: 17, high: 23 } },
  { optionVal: '11to7', display: '11pm to 7am', dbVal: { low: 23, high: 7 } },
]

export const ADL_TYPES = [
  { value: 'AMBULATION_TRANSFER', display: 'Ambulation & Transfer'},
  { value: 'BATHING_SHOWERING', display: 'Bathing'},
  { value: 'DRESSING_GROOMING', display: 'Dressing & Grooming'},
  { value: 'EATING', display: 'Eating'},
  { value: 'TOILETING', display: 'Toileting'},
  { value: 'CONTINENCE', display: 'Continence'},
  { value: 'HOUSEWORK', display: 'Housework'},
  { value: 'LAUNDRY', display: 'Laundry'},
  { value: 'MONEY_AND_BILLS', display: 'Money and Bills'},
  { value: 'MEDICATION', display: 'Medication'},
  { value: 'PET_CARE', display: 'Pet Care'},
  { value: 'PREPARING_MEALS', display: 'Preparing Meals'},
  { value: 'SHOPPING', display: 'Shopping'},
  { value: 'TRAVELING', display: 'Traveling'},
  { value: 'USING_TELEPHONE', display: 'Using Telephone'},
]

export const DAYS_OF_THE_WEEK = [
  { value: 'MON', display: 'Mon', shortDisplay: 'M' },
  { value: 'TUE', display: 'Tue', shortDisplay: 'T' },
  { value: 'WED', display: 'Wed', shortDisplay: 'W' },
  { value: 'THUR', display: 'Thur', shortDisplay: 'Th' },
  { value: 'FRI', display: 'Fri', shortDisplay: 'F' },
  { value: 'SAT', display: 'Sat', shortDisplay: 'S' },
  { value: 'SUN', display: 'Sun', shortDisplay: 'Su' },
]

export const PATIENT_STATUS_COLOR_MAP = {
  'NEW': 'gold',
  'NEEDS_REVIEW': 'orange',
  'IN_REVIEW': 'blue',
  'AVAILABLE': 'purple',
  'CLAIMED': 'green',
  'DECLINED': 'red',
  'ESCALATED': 'red',
  'ACTIVE': 'geekblue',
  'ERROR': 'red',
  'PATIENT_DECLINED': 'red',
}

export const PATIENT_STATUS_TEXT_MAP = {
  'NEW': 'NEW',
  'NEEDS_REVIEW': 'NEEDS REVIEW',
  'IN_REVIEW': 'IN REVIEW',
  'AVAILABLE': 'AVAILABLE',
  'CLAIMED': 'CLAIMED',
  'DECLINED': 'DECLINED',
  'ESCALATED': 'ESCALATED',
  'ACTIVE': 'ACTIVE',
  'ERROR': 'ERROR',
  'PATIENT_DECLINED': 'PATIENT DECLINED',
}

export const PATIENT_ROUTING_STATUS_COLOR_MAP = {
  'NOT_ROUTED': null,
  'ROUTING': 'orange',
  'ROUTED': 'green',
  'NO_MATCHING_AGENCIES': 'orange',
  'NO_MATCHING_AGENCIES_ZIP': 'red',
  'NO_MATCHING_AGENCIES_INTERVENTION': 'red',
}

export const PATIENT_ROUTING_STATUS_STRING_MAP = {
  'NOT_ROUTED': 'NOT REQUESTED',
  'ROUTING': 'ROUTING...',
  'ROUTED': 'REQUESTED',
  'NO_MATCHING_AGENCIES': 'FAILED TO ROUTE',
  'NO_MATCHING_AGENCIES_ZIP': 'MISSING ZIP',
  'NO_MATCHING_AGENCIES_INTERVENTION': 'MISSING INTERVENTION',
}

export const USER_TASK_STATUS_MAP = {
  'DEFAULT': { color: 'orange', label: 'Unknown' },
  'COMPLETE': { color: 'green', label: 'Complete' },
  'CREATED': { color: 'orange', label: 'Created' },
  'WONT_COMPLETE': { color: 'purple', label: "Won't Complete" },
  'CANCELLED': { color: 'red', label: 'Canceled' },
  'IN_PROGRESS': { color: 'blue', label: 'In Progress' }
}

export const TASKLOG_STATUS_MAP = {
  'COMPLETE': { color: 'green', icon: 'check-square' },
  'INCOMPLETE': { color: 'yellow', icon: 'warning' },
  'SKIPPED': { color: 'red', icon: 'close-circle' }
}

export const CARELOG_STATUS_MAP = {
  'SCHEDULED': { color: 'green', icon: 'check-square' },
  'NOT_SCHEDULED': { color: 'yellow', icon: 'warning' },
  'COMPLETE': { color: 'green', icon: 'check-square' },
  'NOT_COMPLETE': { color: 'yellow', icon: 'warning' },
  'CANCELLED_BY_CLIENT': { color: 'yellow', icon: 'warning' },
  'CANCELLED_BY_CAREGIVER': { color: 'yellow', icon: 'warning' },
  'CAREGIVER_NO_SHOW': { color: 'yellow', icon: 'warning' },
  'TENTATIVE': { color: 'yellow', icon: 'warning' },
  'ATTENTION_REQUIRED': { color: 'yellow', icon: 'warning' },
  'CAREGIVER_UNAVAILABILITY': { color: 'yellow', icon: 'warning' },
  'OPEN_SHIFT': { color: 'yellow', icon: 'warning' },
  'IN_PROGRESS': { color: 'yellow', icon: 'warning' },
  'PENDING_CONFIRMATION': { color: 'yellow', icon: 'warning' }
}

export const ENROLLMENT_STATUS_MAP = {
  'INITIATED': { color: 'grey', label: 'Initiated' },
  'SIGNED_UP': { color: 'blue', label: 'Signed Up' },
  'ENROLLED': { color: 'orange', label: 'Enrolled' },
  'ENABLED': { color: 'green', label: 'Enabled' },
  'DELETED': { color: 'red', label: 'Deleted' },
}

export const CONTACT_RELATION_TYPES = { 
  FATHER: 'Father',
  MOTHER: 'Mother',
  FATHER_IN_LAW: 'Father-in-law',
  MOTHER_IN_LAW: 'Mother-in-law',
  SPOUSE: 'Spouse',
  BROTHER: 'Brother',
  SISTER: 'Sister',
  SON: 'Son',
  SON_IN_LAW: 'Son-in-law',
  DAUGHTER: 'Daughter',
  DAUGHTER_IN_LAW: 'Daughter-in-law',
  GRANDSON: 'Grandson',
  GRANDDAUGHTER: 'Granddaughter',
  OTHER_RELATIONSHIP: 'Other',
  DOCTOR: 'Doctor',
  NURSE: 'Nurse',
  SOCIAL_WORKER: 'Social Worker',
  DISCHARGE_PLANNER: 'Discharge Planner',
  Geriatric_Care_Manager: 'Geriatric Care Manager (GCM) ',
  FAMILY: 'Family',
  FRIEND: 'Friend',
  INSURANCE_COMPANY: 'Insurance Company',
  MEDICAL_PROFESSIONAL: 'Medical Professional',
  NEIGHBOR: 'Neighbor',
  OTHER: 'Other'
}

export const GENDERS = { 
  M: 'Male',
  F: 'Female',
  O: 'Other',
  U: 'Undetermined',
}
