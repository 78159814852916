import gql from 'graphql-tag'
import { Query } from 'react-apollo'


export const GQL = gql`
    query searchOrganizations($query: String!, $offset: Int!, $limit: Int!, $activeFilter: Boolean, $enabledAsReferrer: Boolean, $sortOrder: [String!]) {
        searchOrganizations(limit: $limit, offset: $offset, query: $query, activeFilter: $activeFilter, enabledAsReferrer: $enabledAsReferrer, sortOrder: $sortOrder){
            total
            items {
                id
                name
                photo
                status
                updatedAt
                createdAt
                deletedAt
                enabledAsReferrer
                active
                clearCareLinkedAgency{
                    agencyId
                }
            }
        }
    }
`;

export default WrappedComponent => props => {
    const searchOrgSortOrder = (props.searchOrganizationSortOrder && props.searchOrganizationSortOrder.length > 0) ? props.searchOrganizationSortOrder : null;
    let variables = {
        query: props.searchOrganizationQuery || '',
        offset: props.searchOrganizationOffset || 0,
        limit: props.searchOrganizationLimit || 5,
        sortOrder: searchOrgSortOrder || ["name.keyword_norm", "-createdAt"],
    };
    if (props.searchOrganizationActiveFilter != undefined) {
        variables['activeFilter'] = props.searchOrganizationActiveFilter;
    }
    if (props.searchOrganizationEnabledAsReferrer != undefined) {
        variables['enabledAsReferrer'] = props.searchOrganizationEnabledAsReferrer;
    }
    return (
        <Query query={GQL} variables={variables} fetchPolicy="no-cache"  >
            {({ loading, error, data, refetch }) => {
                return <WrappedComponent
                    {...props}
                    organizationSearchLoading={loading}
                    organizationSearchError={error}
                    organizationSearchData={data}
                    organizationSearchRefetch={refetch}>
                </WrappedComponent>
            }}
        </Query>
    )
}
