import Link from 'next/link'
import { withRouter } from 'next/router'

const WrappedLink = (props) => {
  const { title, path, as, pathActiveIfContains, router, primaryColor, secondaryColor, textColor, textColorInactive, textActiveWeight } = props;
  const { asPath, pathname } = router;
  const dataCy = props['data-cy'] ? props['data-cy'] : `${path}Link`;
  const comparedWith = as ? as : path;
  let isActive = as ? asPath.replace(/\/$/, "") === comparedWith : pathname === comparedWith;

  if(pathActiveIfContains){
    isActive = pathname.indexOf(pathActiveIfContains) > -1;
  }
  return (
    <div>
      <li>
        <Link prefetch href={path} as={as}>
          <a className={`nav-item ${isActive ? 'active' : ''}`} href={as || path} data-cy={dataCy}>{title}</a>
        </Link>
      </li>
      <style jsx>{`
      .nav-item{
        display: block;
        margin-right: 1.5em;
        font-weight: 500;
        line-height: 1.5;
        color: ${textColorInactive};
        text-align: left;
        padding: 10px 0px;
        user-select: none;
        cursor: pointer;
        font-size: 1.0em;
      }
      .nav-item.active{
        border: none;
        border-bottom: solid 3px ${primaryColor};
        color: ${textColor};
        font-weight: ${textActiveWeight};
      }
    `}</style>
    </div>
  );
}


const Header = ({ title, links, router, contentRight, style, isWellSky }) => {
  const primaryColor = isWellSky ? "#28929d" : "#00aeff" 
  const secondaryColor = isWellSky ? "#28929d47" : "#2c75be59" 
  const textColor = isWellSky ? "#243746" : "#182359" 
  const textColorInactive = isWellSky ? textColor : "#666" 
  const textActiveWeight = isWellSky ? 'bold' : '500' 
  const _links = !!links ? links : [];
  return (
    <div className="container row" style={style}>
      <div className="col">
        <h1>{title}</h1>
        <ul className="nav">
          {_links.map(link => <WrappedLink data-cy={`${link.path}-link`} key={link.title} router={router} title={link.title} path={link.path} as={link.as} pathActiveIfContains={link.pathActiveIfContains} primaryColor={primaryColor} secondaryColor={secondaryColor} textColor={textColor} textColorInactive={textColorInactive} textActiveWeight={textActiveWeight} />)}
        </ul>
      </div>
      <div style={{flex: 1}} />
      <div className="row">
          {contentRight && contentRight}
      </div>
      <style jsx>{`
      .container{
        width: 100%;
        background-color: white;
        padding: 1.5em 2em 0;
        border-bottom: 1px solid ${secondaryColor};
        position: relative;
        min-height: 90px;
      }
      h1{
        margin: 0;
        padding: 0;
        color: #555;
        line-height: 1.2em;
        font-size: 1.6em;
        font-weight: 600;
      }
      .nav{
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        margin: 10px 0 0;
        border: none;
        cursor: pointer;
        user-select: none;
      }
      
    `}</style>
    </div>
  )
}

export default withRouter(Header)
