import * as Sentry from "@sentry/browser";
import { Auth } from "aws-amplify";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    // https://docs.sentry.io/platforms/javascript/react/
    this.setState({ error });
    let user = null;
    try {
      const data = await Auth.currentAuthenticatedUser();
      const payload = data?.signInUserSession?.idToken?.payload ?? null;
      if (payload) {
        user = {
          id: payload.user_id,
          email: payload.email,
          username: payload.sub
        };
      }
    } catch (error) {}
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      if (user) {
        scope.setUser(user);
      }
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="content">
          <img width="128px" src="/static/rain.png" />
          <h1 className="title">Something went wrong here...</h1>
          <h1 className="subtitle">
            Sorry. We're having some technical issues. Try refreshing the page.
          </h1>
          <h1 className="subtitle">
            Click <a href="/">here</a> to go back
          </h1>
          <a
            onClick={() =>
              Sentry.showReportDialog({ eventId: this.state.eventId })
            }
          >
            Report feedback
          </a>
          <style>{`
                        * {
                            font-family:  Source Sans Pro,sans-serif;
                            box-sizing: border-box;
                            font-size: 1.0em;
                            font-smooth: always;
                            background-color: #EDF1F5;
                        }
                        h1{
                            font-size: 2.0em;
                        }
                        .content{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            min-height: 100vh;
                        }
                        .title{
                           margin: 1.0em;
                           font-size: 2.2em;
                           color: #3798a9;
                        }
                        .subtitle{
                            font-size: 1.2em;
                            color: #AAA;
                        }
                    `}</style>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ({ children }) => (
  <ErrorBoundary>
    <main>
      {children}
      <style>{`
        * {
            // font-family:  Source Sans Pro,sans-serif;
            // box-sizing: border-box;
            // font-size: 1.0em;
            font-smooth: always;
        }
        body {
            margin: 0;
            background-color: #eff3f6;
            display: flex;
            min-height: 100vh;
            flex-direction: column;
        }
        .body {
            margin: 0;
            background-color: #eff3f6;
            display: flex;
            min-height: 100vh;
            flex-direction: column;
        }
        a {
            color: #666;
        }
        a:active, a:visited, a:focus {
            text-decoration: none;
        }
        a:hover {
            color: #666;
        }
        h1{
            font-size: 1.25em;
            color: #354052;
            font-weight: 500;
        }
        h2{
            font-size: 0.75em;
            margin: 0 0 .5rem;
            color: #354052;
            font-weight: 500;
        }
        p {
            font-size: 14px;
            line-height: 24px;
        }
        article {
            // margin: 0 auto;
            // max-width: 650px;
            padding: 10px;
        }
        select {
            cursor: pointer; 
            background: white;
            border: 1px solid #bdbdbd;
            padding: 5px 10px;
            // padding-right: 2em;
            appearance: none;
        }
        .subtext {
            color: #7f8fa4;
            font-weight: 300;
        }
        .error{
            margin: 3px 0px;
            padding: 5px 5px;
            color: red;
        }
        button {
            align-items: center;
            background-color: #1976d2;
            border: 0;
            color: white;
            display: flex;
            padding: 5px 15px;
            transition: background-color 0.3s;
            cursor: pointer;
        }
       
        button.ant-modal-close:hover {
            background: none;
        }
        button:focus {
            outline: none;
        }
        .col{
            display: flex;
            flex-direction: column;
        }
        .row{
            display: flex;
            flex-direction: row;
        }
        .wrap{
            flex-wrap: wrap;
        }

        .fade-in{
            animation: fadeIn 0.5s ease-in;
        }
        .cursor-pointer {
          cursor: pointer;
        }
        .tr-valign-top {
          vertical-align: top
        }
        .tr-valign-bottom {
          vertical-align: bottom
        }
        .animated{
          tranisition: all .3s;
        }
        @keyframes fadeIn {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1.0;
            }
        }
      `}</style>
    </main>
  </ErrorBoundary>
);
