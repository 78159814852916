import App from '../components/App'
import Amplify from './aws'
import Router from 'next/router'
import PageLoading from '../components/PageLoading'
import React, { useEffect, useState } from 'react'


export default (Content) => {
  return class AuthWrapper extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        session: null
      }
    }

    static async getInitialProps(ctx) {
      let session = null;
      if (process.browser) {
        try {
          session = await Amplify.Auth.currentSession();
        } catch (e) {
          console.log(e);
        }
      }
      return { session };
    }

    componentDidCatch(err) {
      console.log(err)
    }

    async componentDidMount() {
      const { session } = this.props;
      try {
        if (session) {
          this.setState({ isLoading: false, session });
          return;
        }
        const _session = await Amplify.Auth.currentSession();
        this.setState({ isLoading: false, session: _session });
      } catch (e) {
        Router.push('/');
      }
    }

    render() {
      const session = !!this.props.session ? this.props.session : this.state.session;
      let content = <PageLoading />;
      if (session) {
        content = <Content {...this.props} session={session} />;
      }
      return <App>{content}</App>
    }
  }
}
