import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { POLICY_DEFAULTS } from '../constants';



const VIEWER = gql`
query viewer {
	viewer{
		id
		fullName
		email
		photo
		isStaff
		currentPolicyMap
		currentOrganization{
			id
			name
			photo
            status
			enabledAsReferrer
			clearCareLinkedAgency {
				agencyId
			}
		}
	}
}
`;

const mappedRoleData = (viewerData) => {
	const roleMap = viewerData && viewerData.currentPolicyMap && JSON.parse(viewerData.currentPolicyMap) || {};
	return roleMap;
}

const canUsePolicy = (viewerData, policy, actions) => {
	const roleMap = mappedRoleData(viewerData);
	const isStaff = (viewerData && viewerData.isStaff);
	let canView = false; //roleMap && actions.includes(roleMap[policy]);
	if(roleMap){
		const roleAction = roleMap[policy] && roleMap[policy]['action'];
		const roleEffect = roleMap[policy] && roleMap[policy]['effect'];
		canView = actions.includes(roleAction) && roleEffect === 'ALLOW'
	}
	return isStaff || canView;
}

export default WrappedComponent => props => (
	<Query query={VIEWER}>
		{({ loading, error, data, refetch }) => {
			const viewerData = data && data.viewer;
			return <WrappedComponent 
				{...props} 
				viewerLoading={loading} 
				viewerError={error} 
				viewerData={viewerData} 
				viewerRefetch={refetch} 
				canViewPolicy={policy => canUsePolicy(viewerData, policy, ['VIEW', 'MANAGE'])}
				canManagePolicy={policy => canUsePolicy(viewerData, policy, ['MANAGE'])}
			></WrappedComponent>
		}}
	</Query>
)