const Input = (props) => {
    const inputProps = { ...props, containerStyle: null };
    delete inputProps['containerStyle'];
    return (
        <div className="input-container" style={props.containerStyle}>
            <input {...inputProps} />
            <style jsx>{`
            input{

                -webkit-appearance: textfield;
                background-color: white;
                -webkit-rtl-ordering: logical;
                cursor: text;
                padding: 1px;
                border-width: 2px;
                border-style: inset;
                border-color: initial;
                border-image: initial;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-size: 16px 18px;
                background-position: 98% 50%;
                cursor: auto;

                display: block;
                width: 100%;
                margin: 0;
                padding: .59375rem .9375rem;
                transition: all .3s;
                border: 1px solid #dfe3e9;
                border-radius: 4px;
                background-color: #fff;
                color: #354052;
                font-size: .875rem;
                font-weight: 400;
                resize: none;
              }
              input::placeholder {
                color: rgba(0,0,0,0.45);
              }
              input:focus {
                border-color: #2ea1f8;
                outline: none;
              }

              .input-container{
                margin: 3px 0px;
              }
            `}</style>
        </div>
    )
}

export default Input;
