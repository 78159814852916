import React from 'react'


export default () => (
    <React.Fragment>
        <div className="container">
            {/* <div className="logo-container">
                <img src="/static/claire-logo.png" width="90px" />
            </div> */}
            <div className="line">
                <div className="inner"></div>
            </div>
            <style jsx>{`

            .container{
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                background-color: #E2E8EF;
                display: flex;
                justify-content: center;
                padding-top: 50px;
                z-index: 99999;
            }

            .logo-container{
                position: absolute;
                top: 50px;
                right: calc(50% - 45px);
            }

            @keyframes slidein {
                0% {
                    transform-origin: left;
                    transform: scalex(0.3);
                }
                25% {
                    transform-origin: left;
                    transform: scalex(1);
                }
                26% {
                    transform-origin: right;
                    transform: scalex(1);
                }
                50% {
                    transform-origin: right;
                    transform: scalex(0.3);
                }
                75% {
                    transform-origin: right;
                    transform: scalex(1);
                }
                76% {
                    transform-origin: left;
                    transform: scalex(1);
                }
                100% {
                    transform-origin: left;
                    transform: scalex(0.3);
                }
            }
           
            .line {
                height: 3px;
                width: 200px;
                background: #a2bddb;
            }
            .inner {
                height: 3px;
                width: 200px;
                background: #0077b5;
                animation: slidein 2s ease-in-out infinite;
                
            }
        
    `}</style>
        </div>
    </React.Fragment>)



