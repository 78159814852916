export default ({style}) => (
    <div className="sk-double-bounce" style={style}>
        <div className="sk-child sk-double-bounce1"></div>
        <div className="sk-child sk-double-bounce2"></div>
        <style jsx>{`
        .sk-double-bounce {
            width: 20px;
            height: 20px;
            position: relative;
        }
            .sk-double-bounce .sk-child {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: white;
              opacity: 0.6;
              position: absolute;
              top: 0;
              left: 0;
              -webkit-animation: sk-doubleBounce 2s infinite ease-in-out;
                      animation: sk-doubleBounce 2s infinite ease-in-out; }
            .sk-double-bounce .sk-double-bounce2 {
              -webkit-animation-delay: -1.0s;
                      animation-delay: -1.0s; }
          @-webkit-keyframes sk-doubleBounce {
            0%, 100% {
              -webkit-transform: scale(0);
                      transform: scale(0); }
            50% {
              -webkit-transform: scale(1);
                      transform: scale(1); } }
          @keyframes sk-doubleBounce {
            0%, 100% {
              -webkit-transform: scale(0);
                      transform: scale(0); }
            50% {
              -webkit-transform: scale(1);
                      transform: scale(1); } }
      `}</style>
    </div>
);
