import React, { useState } from "react";
import Header from "../components/PortalHeader";
import WellSkyHeader from "../components/PortalHeaderWellSky";
import LeftNavigationPortal from "./LeftNavigationPortal";
import ActivityTracker from "./ActivityTracker";

const onMenuClicked = (showMenu, setShowMenu) => () => {
  setShowMenu(!showMenu);
};

export default ({
  session,
  children,
  apolloClient,
  shouldFadeIn,
  hideOrganizationSelector,
  portalHeaderTitle,
  useWellSkyHeader = false
}) => {
  const [showMenu, setShowMenu] = useState(false);
  let fadeInSeconds = "0.0s";

  if (shouldFadeIn) {
    fadeInSeconds = "0.5s";
  }

  return (
    <div className="container">
      <ActivityTracker apolloClient={apolloClient} />
      {useWellSkyHeader && <WellSkyHeader
        onMenuClicked={onMenuClicked(showMenu, setShowMenu)}
        session={session}
        apolloClient={apolloClient}
        hideOrganizationSelector={hideOrganizationSelector}
        portalHeaderTitle={portalHeaderTitle}
      />}
      {!useWellSkyHeader && <Header
        onMenuClicked={onMenuClicked(showMenu, setShowMenu)}
        session={session}
        apolloClient={apolloClient}
        hideOrganizationSelector={hideOrganizationSelector}
      />}
      <div className="layout">
        <LeftNavigationPortal showMenu={showMenu} session={session} />
        <div className="content">{children}</div>
      </div>
      <style jsx>{`
        .container {
          display: flex;
          flex-direction: column;
          flex: 1;
          min-height: 100vh;
          animation: fadeIn ${fadeInSeconds} ease-in;
        }
        .layout {
          display: flex;
          flex: 1;
          flex-direction: row;
          flex-wrap: wrap;
        }
        .content {
          position: relative;
          display: flex;
          flex: 1;
          flex-direction: column;
          margin-left: 80px;
          margin-top: 60px;
          width: calc(100% - 80px);
        }

        @media (max-width: 800px) {
          .content {
            margin: 0px;
            margin-top: 60px;
          }
        }

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};
