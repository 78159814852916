import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'


export const GQL = gql`
mutation switchCurrentOrganization($organizationId: ID!) {
  switchCurrentOrganization(organizationId: $organizationId){
		id
		name
	}
}
`;


export default WrappedComponent => props => (
	<Mutation mutation={GQL}>
		{(switchCurrentOrganization, { data }) => {
			return <WrappedComponent {...props} switchCurrentOrganization={switchCurrentOrganization}></WrappedComponent>
			// return wrapperFn({ ...props, switchCurrentOrganization });
		}}
	</Mutation>
)
