import React, { useState, useEffect } from 'react';
import { withRouter } from 'next/router';
import Router from 'next/router';
import Link from 'next/link';
import { withStateHandlers, withState, compose } from 'recompose';
import Amplify from '../lib/aws';
import UserAvatar from '../components/UserAvatar';
import Spinner from '../components/Spinner';
import OrganizationSelector from '../components/OrganizationSelector';
import { Power, Menu } from 'react-feather';
import { Skeleton, Icon, Badge } from 'antd';
import withViewer from '../graphql/withViewer';

const Header = ({
  signOut,
  isLoading,
  session,
  onMenuClicked,
  viewerData,
  viewerLoading,
  router: { pathname },
  hideOrganizationSelector,
  portalHeaderTitle,
}) => {
  const [showSkeletonTitle, setShowSkeletonTitle] = useState(false);

  const photoUrl = viewerData && viewerData.photo;
  const fullName = viewerData && viewerData.fullName;
  const email = viewerData && viewerData.email;
  const hasNameTag = !!(fullName || email);

  useEffect(() => {
    setShowSkeletonTitle(window.innerWidth > 1024);
    const handleResize = () => {
      setShowSkeletonTitle(window.innerWidth > 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <header>
      <div onClick={evt => onMenuClicked()} className="menu-slideout-btn">
        <Menu />
      </div>
      <img
        src="/static/wellsky_logo2x.png"
        style={{ height: 30, backgroundColor: '#fff', marginLeft: 10, marginRight: 30 }}
      />
      <h1>Care Coordination &ndash; {portalHeaderTitle}</h1>
      <div style={{ flex: 1 }} />
      <Link href="/account">
        <div>
          {!hasNameTag && (
            <div style={{ marginRight: '1.0em' }}>
              <Skeleton
                loading={true}
                active
                paragraph={false}
                avatar={false}
                title={showSkeletonTitle ? { width: '150px' } : false}
              />
            </div>
          )}
          {hasNameTag && (
            <div className="profile-btn">
              <div className="alert-container">
                <Badge count={1} offset={[1, -2]} style={{ backgroundColor: '#d52a32' }}>
                  <Icon style={{ fontSize: 18, color: '#253746' }} type="bell" />
                </Badge>
              </div>
              <Icon style={{ fontSize: 20, color: '#253746' }} type="user" />
              <div className="email">{fullName || email}</div>
            </div>
          )}
        </div>
      </Link>
      <a onClick={signOut} className="logout">
        {' '}
        {!isLoading && <Power />} {isLoading && <Spinner />}
      </a>

      <style jsx>{`
        header {
          position: fixed;
          top: 0px;
          left: 0px;
          right: 0px;
          height: 60px;
          padding: 0px 1em;
          padding: 0px;
          background-color: #fff;
          display: flex;
          flex-direction: row;
          align-items: center;
          z-index: 999;
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.6);
        }
        h1 {
          margin-bottom: 0;
          font-size: 18px;
          color: #000f2d;
        }
        a {
          font-size: 14px;
          margin-right: 15px;
          text-decoration: none;
          color: #666;
        }
        .logout {
          cursor: pointer;
          user-select: none;
          padding: 5px;
          display: flex;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          transition: all 200ms ease;
        }
        .logout:hover {
          color: #fff;
          background-color: rgba(0, 0, 0, 0.3);
        }
        .profile-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          user-select: none;
          padding: 5px 10px;
          margin-right: 10px;
          // transition: all 0.5s ease;
          // animation: fadeIn .75s ease-in;
        }
        .profile-btn :global(.ant-badge-count) {
          height: 16px;
          line-height: 16px;
          min-width: 16px;
          width: 16px;
          padding: 0 2px;
          font-size: 8px;
        }
        .profile-btn :global(.ant-badge-count p) {
          font-size: 10px;
          line-height: 16px;
        }

        .alert-container {
          width: 20px;
          margin-right: 20px;
          font-size: 18px;
          color: #253746;
        }
        .email {
          color: #253746;
          margin: 0px 15px;
          font-size: 1em;
          width: auto;
          min-width: 100px;
          font-weight: normal;
        }
        .is-active {
          text-decoration: underline;
        }
        .menu-slideout-btn {
          display: none;
          color: black;
          margin: 0px 0.75em;
          cursor: pointer;
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @media (max-width: 800px) {
          .email {
            display: none;
          }
          .menu-slideout-btn {
            display: block;
          }
        }
      `}</style>
    </header>
  );
};

export default compose(
  withState('isLoading', 'setIsLoading', false),
  withStateHandlers(
    {},
    {
      signOut: (props, state) => async evt => {
        evt.preventDefault();
        state.setIsLoading(true);
        try {
          const result = await Amplify.Auth.signOut();
          state.apolloClient.cache.reset();
          window.location = '/?loggedOut=true';
        } catch (e) {
          state.setIsLoading(false);
          console.log(e);
        }
      },
    },
  ),
)(withRouter(withViewer(Header)));
