import Amplify from '../lib/aws'

const getIdentityId = async () => {
    const creds = await Amplify.Auth.currentCredentials();
    return creds.data.IdentityId;
}


export {
    getIdentityId
}
