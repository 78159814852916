import { Skeleton } from "antd"


export default ({ Icon, title, isActive, isLoading, titleStyle, ...props }) => {
  if (isLoading) {
    return (
      <div data-cy={props["data-cy"]} className="container">
        <div className="blank-avatar" />
        <div className="blank-title" />
        <style jsx>{`
      .container {
        display:flex;
        flex: 1;
        flex-direction: column;
        color: white;
        justify-content: center;
        align-items: center;
        padding: 0.5em;
        height: 70px;
      }
      .blank-avatar{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #f6f6f6;
      }
      .blank-title{
        width: 50px;
        height: 1.0em;
        margin: 0.5em;
        padding: 0;
        background-color: #f6f6f6;
      }
    `}</style>
      </div>
    );
  }
  return (
    <div className="container">
      <Icon width="36px" height="36px" />
      <p className="title" style={titleStyle}>{title}</p>
      <style jsx>{`
      .container {
        display:flex;
        flex: 1;
        flex-direction: column;
        background-color: #182359;
        color: white;
        justify-content: center;
        align-items: center;
        padding: 0.5em;
        background-color: ${isActive ? `rgba(0,0,0, 0.35)` : `inherit`};
        height: 70px;
      }
      .title{
        margin: 0;
        padding: 0;
        margin-top: 0.25em;
        font-size: 0.85em;
        font-weight: 100;
      }
    `}</style>
    </div>
  )
}
