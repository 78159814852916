import Link from "next/link";
import NavigationIcon from "./NavigationIcon";
import { withRouter } from "next/router";
import { Icon } from "antd";
import { Settings } from "react-feather";
import withViewer from "../graphql/withViewer";
import { firstValidTeamLink } from "../lib/utils";

const DashboardIcon = () => (
  <Icon type="dashboard" style={{ fontSize: "32px" }} />
);
const AdminIcon = () => <Settings size="32px" />;
const WorkflowIcon = () => (
  <Icon type="deployment-unit" style={{ fontSize: "32px" }} />
);
const FormsIcon = () => <Icon type="file-done" style={{ fontSize: "32px" }} />;
const ReferralsIcon = () => <Icon type="idcard" style={{ fontSize: "32px" }} />;
const TeamIcon = () => <Icon type="team" style={{ fontSize: "32px" }} />;
const TasksIcon = () => <Icon type="project" style={{ fontSize: "32px" }} />;
const NPMIcon = () => <Icon type="medicine-box" style={{ fontSize: "32px" }} />;

const adminNavigationIconHighlighted = pathname =>
  pathname.indexOf("/admin/") > -1 &&
  !(pathname.indexOf("/admin/patients") > -1);

const Content = props => {
  const {
    session,
    viewerLoading,
    viewerError,
    viewerData,
    showMenu,
    canViewPolicy,
    canManagePolicy,
    router: { pathname }
  } = props;
  const { isStaff, currentOrganization } = viewerData || {};
  const isOrgReferrer =
    currentOrganization && currentOrganization.enabledAsReferrer;
  const isLoading = viewerLoading;

  let showMenuStyle = "";
  if (showMenu) {
    showMenuStyle = "show-menu";
  }
  const isTeamMenuItemActive =
    pathname === "/members" ||
    pathname === "/usergroups" ||
    pathname === "/roles";
  const isPatientsMenuItemActive =
    pathname === "/patients/list" || pathname === "/patients/edit";
  const canViewTeamMenuItem =
    canViewPolicy("USER") ||
    canViewPolicy("ROLES") ||
    canViewPolicy("USER_GROUP");

  return (
    <div className={`container ${showMenuStyle}`}>
      <Link href="/dashboard">
        <a>
          <NavigationIcon
            isLoading={isLoading}
            Icon={DashboardIcon}
            title={"Dashboard"}
            isActive={pathname === "/dashboard"}
          />
        </a>
      </Link>
      {isLoading && (
        <React.Fragment>
          <a>
            <NavigationIcon isLoading={true} isActive={false} />
          </a>
          <a>
            <NavigationIcon isLoading={true} isActive={false} />
          </a>
          <a>
            <NavigationIcon isLoading={true} isActive={false} />
          </a>
          <a>
            <NavigationIcon isLoading={true} isActive={false} />
          </a>
        </React.Fragment>
      )}
      {canViewTeamMenuItem && (
        <Link href={firstValidTeamLink(canViewPolicy)}>
          <a data-cy="membersLink">
            <NavigationIcon
              isLoading={isLoading}
              Icon={TeamIcon}
              title={"Team"}
              isActive={isTeamMenuItemActive}
            />
          </a>
        </Link>
      )}
      {isStaff && (
        <Link href="/workflows">
          <a data-cy="workflowsLink">
            <NavigationIcon
              isLoading={isLoading}
              Icon={WorkflowIcon}
              title={"Workflows"}
              isActive={pathname.indexOf("/workflows") > -1}
            />
          </a>
        </Link>
      )}
      {isStaff && (
        <Link href="/forms">
          <a data-cy="formsLink">
            <NavigationIcon
              isLoading={isLoading}
              Icon={FormsIcon}
              title={"Forms"}
              isActive={pathname.indexOf("/forms") > -1}
            />
          </a>
        </Link>
      )}
      {canViewPolicy("PATIENT") && (
        <Link href="/patients/list" as="/patients">
          <a data-cy="patientsLink">
            <NavigationIcon
              isLoading={isLoading}
              Icon={ReferralsIcon}
              title={"Patients"}
              isActive={isPatientsMenuItemActive}
            />
          </a>
        </Link>
      )}
      {canViewPolicy("USER_TASK") && (
        <Link href="/user-tasks/list" as="/user-tasks">
          <a data-cy="userTasksLink">
            <NavigationIcon
              isLoading={isLoading}
              Icon={TasksIcon}
              title={"Tasks"}
              isActive={pathname.indexOf("/user-tasks") > -1}
            />
          </a>
        </Link>
      )}
      {canManagePolicy("ORG_INTERVENTIONS") && (isStaff || isOrgReferrer) && (
        <Link href="/care">
          <a data-cy="ppmLink">
            <NavigationIcon
              isLoading={isLoading}
              Icon={NPMIcon}
              title={"Care"}
              isActive={pathname.indexOf("/care") > -1}
            />
          </a>
        </Link>
      )}
      <div style={{ flex: 1 }}></div>
      {isStaff && (
        <Link href="/admin/dashboard">
          <a data-cy="adminLink">
            <NavigationIcon
              isLoading={isLoading}
              Icon={AdminIcon}
              title={"Admin"}
            />
          </a>
        </Link>
      )}

      <style jsx>{`
        a {
          text-decoration: none;
          transition: background-color 0.3s;
          cursor: pointer;
        }
        a:hover {
          background-color: rgba(0, 0, 0, 0.25);
        }
        .container {
          position: fixed;
          top: 60px;
          left: 0px;
          bottom: 0px;
          display: flex;
          flex-direction: column;
          width: 80px;
          min-width: 80px;
          background-color: rgb(33, 64, 95);
          z-index: 99;
          color: white;
          // padding-bottom: 18px;
          transition: all 0.25s ease;
          user-select: none;
        }

        .show-menu {
          transform: translateX(0%);
          box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.5);
        }

        @media (max-width: 800px) {
          .container {
            top: 60px;
            bottom: 0px;
            left: 0px;
            right: 80px;
            transform: translateX(-100%);
          }

          .show-menu {
            transform: translateX(0%);
            box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.5);
          }
        }
      `}</style>
    </div>
  );
};

export default withRouter(withViewer(Content));
