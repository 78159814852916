import { X } from "react-feather"
import { Affix } from 'antd'


const Panel = (props) => {
    const {containerStyle, contentStyle, showCloseButton, onClose, contentRight, noHeader} = props;

    return (
        <div onClick={evt => evt.stopPropagation()} className="container" style={containerStyle}>
            {
                !noHeader && (
                    <Affix offsetTop={60}>
                        <div className="header row">
                            <h1>{props.title}</h1>
                            <div style={{ flex: 1 }} />
                            {contentRight && contentRight}
                            {showCloseButton && <div onClick={evt => onClose()} className="close-btn"><X color="#999" /></div>}
                        </div>
                    </Affix>
                )
            }
            <div className="content" style={contentStyle}>
                {props.children}
            </div>
            <style jsx>{`
            .container{
                position: relative;
                margin: 15px 15px;
                font-size: 1.0em;
                border: 1px solid #e6eaee;
                border-radius: 4px;
                background-color: #fff;
              }
            .header{
                padding: 1.0rem 1.25rem;
                border-bottom: 1px solid #e6eaee;
                background-color: #fafbfc;
                align-items: center;
              }
            h1{
                margin: 0;
                padding: 0;
                color: #354052;
                font-weight: 500;
                user-select: none;
            }
            .content{
                margin: 15px 15px;
                font-size: 1.0em;
                min-height: 200px;
            }
            .close-btn{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 3px;
                border-radius: 50%;
                transition: background-color 0.3s;
                cursor: pointer;
            }
            .close-btn:hover{
                background-color: rgba(0,0,0,.05);
            }
            `}</style>
        </div>
    )
}

export default Panel;
