import { stringToColor } from '../lib/utils'
import { Avatar, Spin, Icon } from 'antd'


const antIcon = <Icon type="loading" style={{ fontSize: 24, color: "white" }} spin />;


const OrganizationImageAvatar = props => {
  const wrapperClass = (props.isRect) ? "org-rect-container" : "org-container";
  const imgClass = (props.isRect) ? "org-rect" : "org-round";
  return (
    <div className={wrapperClass}>
      {props.photo && <img className={imgClass} src={props.photo} />}
      <style jsx>{`
        .org-container {
          align-items: center;
          border: 1px solid rgba(0,0,0,0.12);
          border-radius: 50%;
          display: flex;
          height: 40px;
          width: 40px;
          justify-content: center;
          background-color: #f6f6f6;
        }
        img.org-round{
          border-radius: 50%;
          height: 34px;
          width: 34px;
          object-fit: cover;
        }
        .org-rect-container {
          align-items: center;
          border: 1px solid rgba(0,0,0,0.12);
          display: flex;
          justify-content: center;
          background-color: #fff;
        }
        img.org-rect{
          height: 40px;
          width: 100px;
          object-fit: contain;
        }
      `}</style>
    </div>
  )
}


export default props => {
  const { isLoading } = props;
  const letterSplit = props.title && props.title.split(' ') || ['No', 'Organization'];
  let letters = letterSplit.map(word => word[0]).join('');
  if (letters.length > 3) {
    letters = letters.slice(0, 3);
  }
  const shape = (props.isRect) ? 'square' : 'circle';
  const avatarWidth = (props.isRect) ? '100px' : '40px';

  if (isLoading) {
    return (
      <div>
        <Avatar shape={shape} style={{ width: avatarWidth, backgroundColor: '#F2F2F2', verticalAlign: 'middle', textTransform: 'uppercase' }} size="large" />
      </div>
    )
  }

  return (
    <div>
      {!props.photo && <Avatar shape={shape} style={{ width: avatarWidth, backgroundColor: stringToColor(letters), verticalAlign: 'middle', textTransform: 'uppercase' }} size="large">
        {letters}
      </Avatar>}
      {props.photo && <OrganizationImageAvatar photo={props.photo} isRect={props.isRect} />}
    </div>
  )
}