import { User } from 'react-feather'
import md5 from 'md5'
import { Avatar } from 'antd'
import { stringToColor } from '../lib/utils'



const Icon = () => (<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGiSURBVFhH7ZXLShxBFEDHjSDiQjDqF4RsFcRtFMxOEx/gbwgKoq7EVRRB/AndqfE3RN0aEyX7KBpE8BnPKbqhFzMO3VMNCnPgwNxbVbem69FdadLkPfIBV/AQbxL9ba4LS2UK/+H/Gl7hBJaCkz+jE+3gZ2xP9Lc52+wzjlHpxvTJ50zUYB7tc41Rt8P9tfBuiF7nB9p3OUSROEaLutT1GEL7HoUoEunyu9/16ED7OiYanu68f+AyRJHwnlvU5a1HugUHIYrEElo0zyFcCFEkevACLexVq4WT2ucvenWj4hvuEZ1gD11q91uHcR9ts89XLAXfhk/oRNW0rbRX8Rj+xmoTZ/2FoxiNFlzD7CTn+BHbEj8lubTd78F3dGzDrKNFH3ARPWDGG+jh7MXNJGebN8a+xqvYEN/QQnc4YgIm0dh8VnPpV/AL3qN5t64QrfgHLTJrIsMget99YvVWDGAWv5qOPUNr5WYaLXCCRfbSMT/RGt6e3Gyjg2dCVAxXzhpbIcqJS+fgvhAVox+tcRqinNyigztDVAzHWsNaTZq8RSqVFxL+iRriEq6xAAAAAElFTkSuQmCC" />);

export default ({ url, email, photo, fullName, isRect }) => {

  const shape = isRect ? 'square' : 'circle';
  const avatarWidth = isRect ? '100px' : '34px';

  const letterSplit = fullName && fullName.split(' ') || ['No', 'Name'];
  let letters = letterSplit.map(word => word[0]).join('');
  if (letters.length > 3) {
    letters = letters.slice(0, 3);
  }
  if (!url && email) {
    //https://en.gravatar.com/site/implement/images/
    // url = `https://api.adorable.io/avatars/50/${email}.png`
    // https://avatars.dicebear.com/
    // url = `https://robohash.org/${email}.png?set=set3`
    url = `https://www.gravatar.com/avatar/${md5(email)}?d=robohash`;
  }

  return (
    <div className="container">
      {!url && <Avatar shape={shape} style={{ width: avatarWidth, backgroundColor: stringToColor(letters), textTransform: 'uppercase' }}>
        {letters}
      </Avatar>}
      {url && <img src={url} />}
      <style jsx>{`
        .container {
          align-items: center;
          border: 1px solid rgba(0,0,0,0.12);
          border-radius: 50%;
          display: flex;
          // height: 38px;
          // width: 38px;
          justify-content: center;
          background-color: #f6f6f6;
        }
        img{
          border-radius: 50%;
          height: 34px;
          width: 34px;
          object-fit: cover;
        }
      `}</style>
    </div>
  )
}
