import {  Icon } from 'antd';

export default ({ style, className }) => (
  <div className={`loading-container ${className}`} style={style}>
    <Icon type="loading" style={{ fontSize: 24, color: "#2196f3" }} spin /> <span style={{ marginLeft: '10px' }}>Loading...</span>
    <style jsx>{`
       .loading-container{
        display: flex;
        // justify-content: center;
        color: #2196f3;
        // margin: 20px;
      }
      `}</style>
  </div>
)
