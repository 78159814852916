import React, { useRef, useEffect } from "react";
import IdleTimer from "react-idle-timer";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { logout } from "../lib/utils";

export const TRACK_ACTIVITY_GQL = gql`
  mutation trackActivity($onlyCheckLastActivity: Boolean!) {
    trackActivity(onlyCheckLastActivity: $onlyCheckLastActivity)
  }
`;

export default props => {
  const idleTimerRef = useRef();
  const [trackActivity] = useMutation(TRACK_ACTIVITY_GQL);

  const sessionActive = async onlyCheckLastActivity => {
    try {
      const result = await trackActivity({
        variables: { onlyCheckLastActivity }
      });
      const { data = {} } = result;
      if (data.trackActivity) return;
      logout(props.apolloClient);
    } catch (error) {}
  };

  useEffect(() => {
    sessionActive(false);
  }, []);

  const onAction = evt => {
    sessionActive(false);
  };

  const onActive = evt => {
    sessionActive(false);
  };

  const onIdle = async evt => {
    idleTimerRef.current.reset();
    sessionActive(true);
  };

  return (
    <IdleTimer
      ref={idleTimerRef}
      element={document}
      onAction={onAction}
      onActive={onActive}
      onIdle={onIdle}
      timeout={10000}
      debounce={2500}
    />
  );
};
