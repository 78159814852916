import React, { useState, useEffect } from 'react'
import { withRouter } from 'next/router'
import Router from 'next/router'
import Link from 'next/link'
import { withStateHandlers, withState, compose } from 'recompose'
import Amplify from '../lib/aws'
import UserAvatar from '../components/UserAvatar'
import Spinner from '../components/Spinner'
import OrganizationSelector from '../components/OrganizationSelector'
import { Power, Menu } from 'react-feather'
import { Skeleton } from 'antd'
import withViewer from '../graphql/withViewer'


const Header = ({ signOut, isLoading, session, onMenuClicked, viewerData, viewerLoading, router: { pathname }, hideOrganizationSelector }) => {
  const [showSkeletonTitle, setShowSkeletonTitle] = useState(false);

  const photoUrl = viewerData && viewerData.photo;
  const fullName = viewerData && viewerData.fullName;
  const email = viewerData && viewerData.email;
  const hasNameTag = !!(fullName || email);

  useEffect(() => {
    setShowSkeletonTitle(window.innerWidth > 1024);
    const handleResize = () => {
      setShowSkeletonTitle(window.innerWidth > 1024);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });

  return (
    <header>
      <div onClick={evt => onMenuClicked()} className="menu-slideout-btn"><Menu /></div>
      {!hideOrganizationSelector && <OrganizationSelector isLoading={viewerLoading} />}
      {hideOrganizationSelector && <img src="/static/clearcare-banner.png" style={{height: 50, backgroundColor: '#fff', marginLeft: 5 }} />}
      <div style={{ flex: 1 }} />
      <Link prefetch href='/account'>
        <div>
          {!hasNameTag && <div style={{marginRight: '1.0em'}}><Skeleton loading={true} active paragraph={false} avatar={{ size: 'large', shape: 'circle' }} title={showSkeletonTitle ? { width: '150px' } : false} /></div>}
          {hasNameTag && <div className="profile-btn">
              <UserAvatar url={photoUrl} email={email} />
            <div className="email">{fullName || email}</div>
          </div>}
        </div>
      </Link>
      <a onClick={signOut} className="logout"> {!isLoading && <Power />} {isLoading && <Spinner />}</a>

      <style jsx>{`
      header {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 60px;
        padding: 0px 1.0em;
        padding: 0px;
        background-color: #2d75be;
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 999;
      }
      a {
        font-size: 14px;
        margin-right: 15px;
        text-decoration: none;
        color: white;
      }
      .logout {
        cursor: pointer;
        user-select: none;
        padding: 5px;
        display: flex;
        justify-content: center;
        background-color: rgba(0,0,0, 0.25);
        border-radius: 50%;
        transition: all 0.5s ease;
      }
      .logout:hover {
        background-color: rgba(0,0,0, 0.80);
      }
      .profile-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        padding: 5px 10px;
        margin-right: 10px;
        // transition: all 0.5s ease;
        // animation: fadeIn .75s ease-in;
      }
      .profile-btn:hover {
        background-color: rgba(0,0,0, 0.25);
      }
      .email {
        color: white;
        margin: 0px 15px;
        font-size: 1.0em;
        width: auto;
        min-width: 100px;
        font-weight: normal;
      }
      .is-active {
        text-decoration: underline;
      }
      .menu-slideout-btn{
        display: none;
        color: white;
        margin: 0px 0.75em;
        cursor: pointer;
      }
      @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1.0;
        }
      }
      @media (max-width: 800px) { 
        .email {
          display: none;
        }
        .menu-slideout-btn{
          display: block;
        }
      }
    `}</style>
    </header>
  )
}

export default compose(
  withState('isLoading', 'setIsLoading', false),
  withStateHandlers(
    {},
    {
      signOut: (props, state) => async (evt) => {
        evt.preventDefault();
        state.setIsLoading(true);
        try {
          const result = await Amplify.Auth.signOut();
          state.apolloClient.cache.reset();
          window.location = "/?loggedOut=true";
        } catch (e) {
          state.setIsLoading(false);
          console.log(e)
        }
      }
    })
)(withRouter(withViewer(Header)));
