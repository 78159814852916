import { createInflate } from "zlib";
import { v4 as uuid } from "uuid";
import AWS_AppSync from "../AppSync.json";
import { getIdentityId } from "./cognito";
import Amplify from "./aws";
import Router from "next/router";
import mime from "mime-types";

/**
 * Returns a hash code for a string.
 * (Compatible to Java's String.hashCode())
 *
 * The hash code for a string object is computed as
 *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
 * using number arithmetic, where s[i] is the i th character
 * of the given string, n is the length of the string,
 * and ^ indicates exponentiation.
 * (The hash value of the empty string is zero.)
 *
 * @param {string} s a string
 * @return {number} a hash code value for the given string.
 */
export function hashCode(s) {
  var h = 0,
    l = s.length,
    i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
}

const MATERIAL_COLORS = [
  "#F56A00",
  "rgb(249, 166, 109)",
  "rgb(87, 190, 140)",
  "rgb(132, 122, 140)",
  "rgb(236, 94, 68)",
  "rgb(33, 33, 33)"
];

export function stringToColor(s) {
  const code = hashCode(s);
  const idx = code % MATERIAL_COLORS.length;
  return MATERIAL_COLORS[idx];
}

export function generateTeamLinks(canViewPolicy) {
  const headerLinks = [];
  if (canViewPolicy("USER")) {
    headerLinks.push({ title: "Members", path: "/members" });
  }
  if (canViewPolicy("USER_GROUP")) {
    headerLinks.push({ title: "User Groups", path: "/usergroups" });
  }
  if (canViewPolicy("ROLES")) {
    headerLinks.push({ title: "Roles", path: "/roles" });
  }
  return headerLinks;
}

export function firstValidTeamLink(canViewPolicy) {
  if (canViewPolicy("USER")) {
    return "/members";
  }
  if (canViewPolicy("USER_GROUP")) {
    return "/usergroups";
  }
  if (canViewPolicy("ROLES")) {
    return "/roles";
  }
  return null;
}

export async function createPrivateAWSFile(file) {
  const cognitoIdentityId = await getIdentityId();
  const { name: fileName } = file;
  const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
  // if no mimetype detected (windows chrome) try to set it based on extension
  const mimeType = !file.type ? mime.lookup(extension) : file.type;
  const key = [uuid(), extension].filter(x => !!x).join(".");
  const keyWithContext = `private/${cognitoIdentityId}/${key}`;
  const awsFile = {
    key: keyWithContext,
    bucket: AWS_AppSync.bucket,
    region: AWS_AppSync.region,
    mimeType,
    localUri: file,
    visibility: "private"
  };
  return awsFile;
}

export async function logout(apolloClient) {
  const result = await Amplify.Auth.signOut();
  if (apolloClient) {
    apolloClient.cache.reset();
  }
  window.location = "/?sessionTimedOut=true";
}

export function formatPhone(phone){
  if (!phone) { return "" }
  return phone.replace(/(\d{3})(\d{3})(\d{4})(x\d{5})?/, "($1) $2-$3 $4")
}